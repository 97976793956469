@import "../../assets/styles/scss/variables";

.header-image {
    height: 65vh;
    margin-top: -5.1rem;

    img {
        height: 65vh;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }

    @media (max-width:991px) {
        height: 60svh;
        margin-top: -4.1rem;

        img {
            height: 60svh;
        }
    }
}