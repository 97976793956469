@import "../../assets/styles/scss/variables";

.header-homepage {
    height: calc(100svh);
    min-height: calc(100svh);
    margin-top: -5.1rem;

    .swiper {
        height: calc(100svh);
        min-height: calc(100svh);
        background-color: $color-brown-dark;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        .swiper-slide {


            &:before {
                z-index: 10;
                position: absolute;
                content: "";
                width: 100%;
                height: 100svh;
                bottom: 0;
            }

            &.no-quote {
                &:before {
                    background-color: transparent;
                }
            }

            &.backdrop {
                &::before {
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.4) 100%);
                }
            }
        }

        .swiper-pagination {
            bottom: 11rem;
            z-index: 11;

            .swiper-pagination-bullet {
                background-color: $color-white;
                opacity: 0.5;
            }

            .swiper-pagination-bullet-active {
                opacity: 1;
            }
        }
    }

    &.no-quote {
        .swiper {
            .swiper-pagination {
                bottom: 6rem;
            }
        }
    }

    #hv-quote {
        width: 100%;
        position: absolute;
        bottom: 0;
        color: $color-beige;
        min-height: 9rem;
        padding: 1.2rem 0;
        text-align: center;
        display: flex;
        align-items: center;
        text-align: center;

        .quote {
            z-index: 11;
            font-size: clamp(1.2rem, 2vw, 1.5rem);
            line-height: clamp(1.5rem, 1.5vw, 2rem);
        }

        .smaller-container {
            display: flex;
            gap: 1.80rem;
            flex-direction: column;
            align-items: center;
        }

        @media (max-width:767px) {
            display: none;
        }
    }

    @media (max-width:991px) {
        margin-top: -4.1rem;
    }


    @media (max-width:767px) {

        &.no-quote {
            .swiper {
                .swiper-pagination {
                    bottom: 6rem;
                }
            }
        }

        .swiper {
            .swiper-pagination {
                bottom: 6rem;
            }
        }
    }
}