//
// Main Sass File
// --------------------------------------------------

// Components styles
$fa-font-path: "../webfonts/fontawesome/";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/brands.scss";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/light.scss";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/thin.scss";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/regular.scss";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/solid.scss";
@import "../../../node_modules/@fortawesome/fontawesome-pro/scss/duotone.scss";
@import "../../../node_modules/@fancyapps/ui/dist/fancybox/fancybox.css";
@import "../../../node_modules/mmenu-light/dist/mmenu-light.css";
@import "../../../node_modules/swiper/swiper.css";
@import "../../../node_modules/swiper/swiper-bundle";
@import "../../../node_modules/sal.js/dist/sal.css";
@import "../../../node_modules/nice-select2/dist/css/nice-select2.css";

// Main theme styles
@import "base";
@import "header";
@import "general";
@import "fonts";
@import "footer";
@import "template";
@import "variables";

//Components styles
@import "../../../components/hv-accordion/main";
@import "../../../components/hv-header-homepage/main";
@import "../../../components/hv-header-image/main";  
@import "../../../components/hv-logo/main";  
@import "../../../components/hv-header-standard/main"; 
@import "../../../components/hv-member-block/main";
@import "../../../components/hv-project-block/main";
@import "../../../components/hv-socials/main";