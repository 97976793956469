//
// Header Styles
// --------------------------------------------------
@import "../../../assets/styles/scss/variables";

#header {
	width: 100%;
	height: 5.1rem;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 120;
	transition: all 0.4s linear;

	.container {
		height: 100%;
	}

	&.sticky, &.standard {
		background-color: $color-brown-dark;

		#menu {
			#menu-main-container {
				#menu-principal, #menu-principal-fr, #menu-principal-en {
					.menu-item {
						a {
							color: $color-beige;
						}
					}
				}
			}
		}
	}

	#header-content {
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	#logo {
		display: flex;
		height: 100%;
		width: 40%;
		align-items: center;

		a {
			display: flex;
			height: 100%;
			align-items: center;
		}

		@media (max-width:767px) {
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}

	#menu {
		height: 100%;
		transition: all 0.4s linear;

		#menu-main-container {
			display: inline-block;
			width: 100%;
			height: 100%;

			#menu-principal, #menu-principal-fr, #menu-principal-en {
				display: flex;
				height: 100%;
				margin: 0;
				list-style: none;

				.menu-item {
					height: 100%;

					a {
						padding: 0 1.335rem;
						height: 100%;
						font-size: 16px;
						display: flex;
						align-items: center;
						color: #dad9d8;

						&:hover {
							color: $color-white;
						}
					}

					&:last-child {
						margin-right: 0;

						a {
							padding: 0 0 0 1.335rem;
						}
					}

					&:first-child {
						a {
							padding: 0 1.195rem 0 0;
						}
					}

					@media (max-width:1199px) {
						a {
							padding: 0 0.9rem;
						}

						&:last-child {
	
							a {
								padding: 0 0 0 0.9rem;
							}
						}

						&:first-child {
							a {
								padding: 0 0.9rem 0 0;
							}
						}
					}

					&.current-menu-item,
					&.current-menu-ancestor,
					&.current-menu-parent,
					&.current-product-ancestor,
					&.current-product_cat-ancestor,
					&.current-page-ancestor {
						>a {
							color: #ffffff;
						}

						.sub-menu {
							li {
								a {
									color: $color-primary;
								}
							}
						}
					}

					.sub-menu {
						opacity: 0;
						height: 0;
						padding: 0;
						position: absolute;
						text-align: left;
						margin-left: -30px;
						transform: scale(0.95) translateY(20px);
						transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
						z-index: 10;
						overflow: hidden;

						li {
							white-space: nowrap;
							margin: 0;
							float: none;

							a {
								display: block;
								color: $color-primary;
								background-color: $color-gray-darker;
								padding: 20px 35px;

								&:hover {
									color: $color-link-hover;
									background-color: lighten($color-gray-darker, 3%);
								}
							}

							&:last-child {
								margin-bottom: 0;
							}

							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								a {
									color: #ffffff;
								}
							}
						}
					}

					&.menu-item-has-children {
						&:after {
							font-family: "Font Awesome 6 Pro";
							content: '\f105';
							display: block;
							position: absolute;
							top: 50%;
							right: -12px;
							font-weight: 100;
							transform: translateY(-50%);
							transform-origin: center;
							color: white;
							transition: transform 0.25s;
						}

						&:hover {
							&:after {
								transform: translateY(-50%) rotate(90deg);
							}

							.sub-menu {
								height: auto;
								opacity: 1;
								transform: scale(1) translateY(0);
								padding-top: 27px;
							}
						}
					}
				}
			}
		}

		#menu-mobile-trigger,
		#menu-mobile-contact {
			display: none;
		}
	}

	@media (max-width:991px) {
		height: 4.1rem;

		&.sticky {
			height: 3.1rem;
		}
	}
}

.mm-ocd {
	background: $color-brown-dark;
	z-index: 10;
	position: fixed;
	bottom: -100%;
	top: auto;
	height: 0;
	transition: all 0.5s ease;

	.mm-ocd__backdrop {
		display: none;
	}

	&.mm-ocd--bottom {
		.mm-ocd__content {
			transform: translate3d(0, 100%, 0);
		}
	}

	&.mm-ocd--close {
		width: 100vw;
		background: $color-brown-dark;
		height: 0;
		transform: translate3d(0, -100%, 0);
		transition: all 0.5s ease;

		* {
			width: 100vw;
		}

		.mm-ocd__content {
			background: $color-brown-dark;
			width: 100vw;
			transform: translate3d(0, 0, 0);

			#menu-main-container {
				background-color: $color-brown-dark !important;
	
				#menu-principal, #menu-principal-fr, #menu-principal-en {
					background: $color-brown-dark !important;
				}
			}
		}
	}

	&.mm-ocd--open {
		top: auto;
		bottom: 0;
		height: 100%;
		width: 100vw;
		z-index: 150;

		.mm-ocd__content {
			background: $color-brown-dark;
			width: 100vw;
			max-width: 100vw;
			transform: translate3d(0, 0, 0);

			#menu-main-container {
				color: $color-beige;
				background-color: $color-brown-dark;

				#menu-principal, #menu-principal-fr, #menu-principal-en {
					position: relative;
					padding-top: 25%;
					top: 1%;
					background: $color-brown-dark !important;

					&:after {
						display: none;
					}

					li {
						background: $color-brown-dark !important;
					}

					&::before {
						opacity: 0;
					}

					a {
						font-size: $medium;
						padding: 1.2rem 2em;
					}

					.current-menu-item {
						font-weight: 700;
					}
				}

				#menu-mobile-contact {
					display: flex;
					flex-direction: column;
					gap: 0.6rem;
					margin-top: 6rem;

					a {
						font-size: $medium;
						padding: 0rem 3rem;

						&:after {
							display: none;
						}
					}
				}
			}
		}
	}
}

.mobile-menu-link-hidden {
	opacity: 0;
	transition: opacity 0.5s ease;
}

.mobile-menu-link-visible {
	opacity: 1;
	transition: opacity 0.5s ease;
}

@media (max-width:991px) {
	#menu {
		z-index: 1000;
		position: fixed;
		bottom: 20px;
        left: calc(50vw - 85px);
		max-height: 45px;
		width: 170px;
		border-radius: 5rem;
		background: $gradient;
		display: flex;
		justify-content: center;
		align-items: center;
        box-shadow: 0 0 20px 0 #4d473c5e;

		.mobile-menu-name {
			font-size: 1rem;
			color: $color-white;
			font-weight: 600;
		}

		#menu-mobile-trigger {
			display: flex;
			gap: 0.9rem;
			align-items: center;
			padding: 0.78rem 1.56rem;

			.trigger-bar {
				display: block;
				width: 15px;
				height: 1px;
				transition: all 0.2s linear;
				position: relative;
				background-color: white;

				&:not(:first-child) {
					margin-top: 3px;
				}
			}

			&.active {
				.trigger-bar:nth-child(1) {
					transform: rotate(45deg) translate(2px, 2px);
					top: 1px;
				}

				.trigger-bar:nth-child(2) {
					opacity: 0;
				}

				.trigger-bar:nth-child(3) {
					transform: rotate(-45deg) translate(2px, -2px);
					bottom: 1px;
				}
			}
		}
	}
}