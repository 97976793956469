//
// General Styles
// --------------------------------------------------
@import "../../../assets/styles/scss/variables";

html {
	margin: 0;
	padding: 0;
	text-size-adjust: 100%;
	overflow-x: hidden !important;
	scroll-behavior: smooth;
}

body {
	font-family: $font-base;
	font-size: clamp($small, 1vw, $regular);
	line-height: $text-base-line-height;
	color: $color-text;
	background-color: #ffffff;
	margin: 0;
	padding: 0;
	overflow-x: hidden !important;
	scroll-behavior: smooth;
}

.cmplz-cookiebanner .cmplz-buttons .cmplz-btn {
	height: auto !important;
}

.quote {
	font-family: $font-quote;
	font-style: normal;
	font-weight: 300;
}

* {
	box-sizing: border-box;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

iframe {
	max-width: 100%;
}

a img,
img {
	border: none;
	max-width: 100%;
	height: auto;
}

a {
	color: $color-link;
	cursor: pointer;
	text-decoration: none;
	transition: all .2s linear;

	&.card {
		color: $color-text;
	}

	&:hover {
		outline: none;
		color: $color-link-hover;
		text-decoration: none;

		i {
			color: $color-link-hover;
		}
	}

	&:focus {
		outline: none;
		text-decoration: none;
	}
}

.animated-link {
	position: relative;
	width: fit-content;

	&:after {
		content: '';
		position: absolute;
		width: 100%;
		transform: scaleX(0);
		border-radius: 5px;
		height: .05em;
		bottom: -1px;
		left: 0;
		opacity: 1 !important;
		background: $color-brown;
		transform-origin: bottom right;
		transition: transform .25s ease-out;
		transition-duration: .3s !important;
	}

	&:hover::after {
		transform: scaleX(1);
		transform-origin: bottom left;
		transition-duration: .3s !important;
	}
}

.swiper-button-next,
.swiper-button-prev {
	color: white;

	&:after {
		font-weight: 100;
		font-size: 34px;

		@media (max-width:767px) {
			font-size: 24px;
		}
	}
}

.btn-primary {
	width: fit-content;
	display: flex;
	gap: 0.7rem;
	background-color: $color-transparent;
	border: solid 1px $color-brown;
	color: $color-brown;
	font-weight: 500;
	letter-spacing: 0;
	font-size: 15px;
	padding: .32rem 1rem;
	position: relative;
	overflow: hidden;
	z-index: 100;

	&.reverse {
		background-color: $color-transparent;
		border: solid 1px $color-white;
		color: $color-white;
		z-index: 101;

		&::after {
			color: $color-white;
			z-index: 99;
		}

		&::before {
			background-color: $color-white;
		}

		&:hover {
			background-color: $color-transparent;
			border: solid 1px $color-white;
			color: $color-brown;

			&::after {
				color: $color-brown;
			}
		}
	}

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 0;
		height: 100%;
		background-color: $color-brown;
		opacity: 1;
		transition: width 0.3s ease-out;
		z-index: -1;
	}

	&:hover::before {
		width: 100%;
	}

	&:hover,
	&:focus {
		background-color: $color-brown;
		border: solid 1px $color-brown;
		color: $color-white;

		&::after {
			color: $color-white;
		}
	}

	&::after {
		font-family: $fontawesome;
		content: '\f078';
		transform: rotate(270deg);
		transition: all 0.3s ease;
		color: $color-brown;
		font-weight: 500;
		z-index: 1;
	}
}


p,
blockquote {
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
}

blockquote {
	border-left: 5px solid $color-gray-lighter;
	padding: 10px 0 10px 25px;
}

table {
	margin-top: $margin-base;
	margin-bottom: 15px;
	border: inherit;
	border-color: inherit;
	border-spacing: 0;
	border-collapse: collapse;

	tr {
		border: inherit;
		border-color: inherit;

		td {
			border-top: 1px solid $color-gray-lighter !important;
			padding: 1rem !important;
			vertical-align: middle !important;
		}
	}
}

address {
	font-style: normal;
}

.gform_required_legend {
	display: none;
}

.gform_validation_errors {
	padding: 0;
	color: $color-red;
	margin-bottom: 25px;
	font-size: 14px;

	.gform_submission_error {
		color: $color-red;
		font-size: 24px;
		line-height: normal;
		margin-bottom: 10px;
	}

	ol {
		padding: 0;
		list-style-position: inside;
	}

	.gform_validation_error_link {
		color: $color-red;
	}
}

.validation_message {
	color: $color-red;
	clear: both;
}

form {
	.gform_body {
		display: table;
		width: 100%;
	}

	.gform_footer {
		margin-top: 20px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
	}

	.gform_hidden {
		display: none !important;
	}

	.gfield_required {
		margin-left: 5px;
		color: $color-brown;
		font-weight: bold;
		transition: all .2s linear;
	}

	.gform_fields {
		margin: 0;
		padding: 0;
		list-style: none;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 25px;
		row-gap: 25px;

		.uploader-btn {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			.uploader-filename {
				margin-right: 60px;
				min-width: 150px;
			}

			button {
				background-color: $color-primary;
				color: $color-text;
				border: 2px solid $color-primary;
				box-shadow: none;

				&:hover,
				&:active,
				&:focus {
					background-color: $color-link-hover;
					color: $color-text;
					border: 2px solid $color-primary !important;
				}
			}
		}

		.gfield {
			margin: 0;
			padding: 0;
			position: relative;
			font-size: 18px;
			line-height: normal;

			&.gfield--width-full {
				grid-column: span 2;
			}

			&.gfield_visibility_hidden {
				display: none;
			}

			&.hidden_label {
				.gfield_label {
					display: none;
				}
			}

			&:focus-within,
			&.not-empty {

				label,
				.gfield_label {
					transform: translateY(-1em);
					font-size: $regular;
					color: $color-brown;
				}

				.gfield_required {
					color: transparent;
				}
			}

			&.field_sublabel_above {
				margin-bottom: 20px;
			}

			&.file-input {
				display: none;
			}

			&.gfield_error {

				input,
				textarea {
					border-color: $color-red;
				}
			}

			.gchoice {
				width: fit-content;
				transition: all .2s linear;

				&:not(:last-child) {
					margin-bottom: 4px;
				}

				input {
					visibility: hidden;
					margin: 0;
					margin-right: 0;
				}

				label {
					padding-left: 35px;
					padding-bottom: 10px;
					font-weight: 400;
					position: relative;
					cursor: pointer;
					left: initial;
					top: initial;
					transition: all .2s linear;

					&::before {
						position: absolute;
						top: 50%;
						left: 0px;
						transform: translateY(-50%);
						content: "";
						width: 16px;
						height: 16px;
						border-radius: 50%;
						border: 2px solid $color-primary;
						background-color: transparent;
						transition: all .2s linear;
					}
				}

				.gfield-choice-input:checked~label {
					&::before {
						background-color: $color-primary !important;

					}
				}

				&:hover {
					label {
						&::before {
							background-color: $color-primary;
						}
					}
				}
			}

			.gfield_checkbox {
				.gchoice {
					label {

						&::before {
							position: absolute;
							top: 50%;
							left: 0px;
							transform: translateY(-50%);
							content: "";
							width: 16px;
							height: 16px;
							border-radius: 3px;
							border: 2px solid $color-primary;
							background-color: transparent;
							transition: all .2s linear;
						}
					}
				}
			}
		}
	}

	label,
	.gfield_label {
		font-size: inherit;
		line-height: inherit;
		color: $color-brown;
		position: absolute;
		top: 16px;
		transition: all .2s linear;
		transform-origin: left top;
	}

	.gfield--type-checkbox {

		.gfield_label,
		label {
			padding-bottom: 10px;
			display: flow;
			font-size: inherit;
			line-height: inherit;
			color: $color-brown;
			position: inherit !important;
			top: 16px;
			transition: all .2s linear;
			transform-origin: left top;
		}

		a {
			text-decoration: underline;
		}
	}

	fieldset {
		border: none;
	}

	select {
		padding: 8px 10px;
		background-color: white;
		height: 42px;
		color: $color-gray;
		width: 100%;
		border: 0;
		border: 1px solid $color-gray-lighter;
	}

	select[multiple] {
		height: auto;
	}

	input,
	textarea {
		width: 100%;
		border: 0;
		border-radius: 0;
		border-bottom: 1px solid $color-brown-dark;
		color: black;
		padding: 1.76rem 0 0.66rem;
		appearance: none;
		resize: none;
		background-color: transparent;
		font-size: 18px;
		line-height: normal;
		font-family: inherit;

		&::placeholder {
			border-radius: 0;
			font-size: inherit;
			color: transparent;
		}

		&:focus {
			border-radius: 0;
			outline: none !important;
			box-shadow: none !important;

			&::placeholder {
				border-radius: 0;
				color: inherit;
				opacity: .3;
			}
		}
	}

	input[type="checkbox"],
	input[type="radio"] {
		width: auto;
		appearance: inherit;
	}

	input[type="checkbox"] {
		appearance: checkbox;
	}

	input[type="radio"] {
		appearance: radio;
	}

	.gfield_description {
		margin-top: 0;
		margin-bottom: 10px;
		font-size: 14px;
		font-style: italic;
	}
}

button {
	all: unset;
}

button,
input[type="submit"],
button[type="submit"],
.product .cart .single_add_to_cart_button,
#error-template .button,
.woocommerce a.button {
	width: fit-content;
	display: flex;
	gap: 0.44rem;
	background-color: $color-transparent;
	border: solid 2px $color-brown;
	color: $color-brown;
	font-weight: 700;
	padding: .32rem 1rem .22rem;
	border-radius: 0;
	text-decoration: none;
	cursor: pointer;
	line-height: inherit;
	text-align: center;
	transition: all .2s linear;

	&.success {
		background-color: $color-green;
		color: $color-text;

		&:hover {
			color: $color-text;
			background-color: lighten($color-green, 5%);
		}
	}

	&.error {
		background-color: $color-red;
		color: $color-text;

		&:hover {
			color: $color-text;
			background-color: lighten($color-red, 5%);
		}
	}

	&:hover {
		color: $color-white;
		background-color: $color-link-hover;
		text-decoration: none;
		border: solid 2px $color-brown-dark;
	}

	&:focus {
		color: $color-white;
		background-color: $color-link-hover;
		text-decoration: none;
		border: solid 2px $color-brown-dark;
	}
}

.container,
.container-fluid {
	position: relative;
}

.container {
	width: 1440px;
	max-width: 90vw;
	margin-inline: auto;

	@media (max-width:599px) {
		max-width: 85vw;
	}
}

.smaller-container {
	width: 75vw;
	max-width: 1440px;
	margin-inline: auto;

	@media (max-width:599px) {
		width: 85vw;
	}
}

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.page-section {
	padding-top: $margin-base;
	padding-bottom: $margin-base;
}

.no-padding-top {
	padding-top: 0;
}

.no-padding-bottom {
	padding-bottom: 0;
}

.no-margin-top {
	margin-top: 0;
}

.no-margin-bottom {
	margin-bottom: 0;
}

#main-content {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		&:first-child {
			margin-top: 0;
		}
	}
}

.acf-map {
	width: 100%;
	height: 700px;
	border: 0;
	border: 0;
}

.highlight {
	color: #ffffff;
	background-color: $color-primary;
}

#error-template {
	.error-details {
		margin-bottom: 30px;
	}

	.error-actions {
		.button {
			&:hover {
				i {
					color: #ffffff;
				}
			}
		}
	}
}

#post-share {
	.social-share {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			display: inline-block;
			margin-right: 30px;

			&:last-child {
				margin-right: 0;
			}

			a {
				i {
					font-size: 25px;
				}
			}
		}
	}
}

#page-header {
	position: relative;

	&.standard {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		overflow: hidden;
		color: #ffffff;
		height: 500px;

		.video {
			position: absolute;
			top: 50%;
			left: 50%;
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			z-index: -1;
			transform: translateX(-50%) translateY(-50%);
		}
	}

	&.slider {
		.slide {
			position: relative;
			height: 500px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			overflow: hidden;
			color: #ffffff;
		}
	}

	.link {
		margin-top: 30px;
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.page-header-content {
		position: absolute;
		left: 0;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);

		.title {
			margin: 0;
		}
	}

}

.loading-spinner {
	width: 50px;
	height: 50px;
	margin: 0 auto;
	float: none;
	background-image: url('../images/ajax-loader.svg');
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
}

#filters {
	.filter-item {
		color: $color-brown-secondary;
		font-size: clamp(.88889rem, 1vw, 1rem);
	}

	ul {
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;
		column-gap: 1.5rem;
		border-bottom: $border-brown-light;
		margin: 0;
		padding: 0.313rem 0;
		flex-wrap: wrap;
	}

	div:last-child ul {
		border-bottom: 0;
	}

	li.active {
		.filter-item {
			color: $color-brown;
			font-weight: 700;
		}
	}

	@media (max-width:599px) {
		.filter-item {
			line-height: 1rem;
		}

		ul {
			column-gap: 1.2rem;
			row-gap: 0.2rem;
		}
	}
}


.filter-error {
	color: $color-brown;
	font-size: $medium;
}

#pagination {
	text-align: center;

	.navigation {
		ul {
			padding: 0;
			margin: 0;

			li {
				display: inline-block;

				a {
					color: #ffffff;
					text-decoration: none;
					background-color: $color-gray-dark;
					cursor: pointer;
					padding: 5px 13px;
					border-radius: 5px;
					display: block;

					&:hover {
						background-color: $color-primary;
					}
				}

				&.active {
					a {
						background-color: $color-primary;
					}
				}
			}
		}
	}
}

#cookie-notice {
	.button {
		font-size: 14px;
		padding: 5px 10px;
	}
}

/* WordPress */
.wp-caption,
.gallery-caption {
	color: #666;
	font-size: 13px;
	font-size: 0.8125rem;
	font-style: italic;
	margin-bottom: 1.5em;
	max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption .wp-caption-text {
	margin: 0.8075em 0;
	font-size: 14px;
}

.bypostauthor>.comment-body>.comment-meta>.comment-author .avatar {
	border: 1px solid #333;
	padding: 2px;
}

.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.alignleft {
	float: left;
	margin-right: 1.5em;
}

img.alignright {
	float: right;
	margin-left: 1.5em;
}

.wpml-floating-language-switcher .wpml-ls-statics-shortcode_actions {
	margin-bottom: 0;
}

.wpml-floating-language-switcher .wpml-ls-statics-shortcode_actions a {
	background-color: transparent !important;
}

.wpml-floating-language-switcher .wpml-ls-legacy-list-horizontal a {
	padding: 5px;
}


/*Customize this if you want*/
.wpml-floating-language-switcher {
	color: $color-white;
	background: $color-brown-dark;
	outline: $color-brown !important;
	border: 0px solid $color-white !important;
	padding: 0px;
	display: flex;
	min-width: 45px;
	border-radius: 0;
	box-shadow: 0;
	font-weight: 700;

	&:after {
		border-bottom: 2px solid $color-white;
		border-right: 2px solid $color-white;
	}

	.list {
		.option {
			color: $color-brown-dark;

			&.selected {
				background-color: $color-beige;
			}

			&:hover {
				background-color: $color-beige;
			}
		}
	}

	option {
		font-size: 0.83333rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
}

.nice-select .nice-select-dropdown {
	border-radius: 0 !important;
}

.nice-select {
	height: 30px;
	line-height: 30px;

	.option {
		padding-left: 19px;
		padding-right: 18px;
	}
}

.fancybox__content {
	padding: 0 !important;
	width: 90vw !important;
	height: 100svh !important;
	background: transparent !important;
}