@import "../../assets/styles/scss/variables";

.accordion {
    border-bottom: $border-brown-light;
    position: relative;

    &:first-child {
        .accordion-title {
            padding: 0 0 1.33rem;
        }
    }

    &:last-child {
        border-bottom: none;
    }

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: $color-primary;
        position: absolute;
        bottom: -2px;
        z-index: 1;
    }

    &:after {
        content: "";
        display: block;
        height: 2px;
        background-color: $color-primary;
        position: absolute;
        bottom: -2px;
        z-index: 1;
        width: 0;
        transition: width 0.3s ease;
    }

    &:hover {
        .accordion-title {
            padding-left: 20px;
            transition: all 0.4s ease;
        }
        &:after {
            width: 100%;
            transition: width 0.3s ease;
        }
    }

    .accordion-title {
        padding-left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        padding: 1rem 0;
        font-weight: 500;
        font-size: clamp(1rem,2vw,1.2rem);
        transition: all 0.4s ease;

        &:after {
            font-family: $fontawesome;
            content: "\f078";
            transition: all .2s linear;
            font-weight: 300;
        }

        &.is-open {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    .accordion-title.is-open+.accordion-content {
        max-height: 100%;
        transition: max-height 0.3s ease;
    }

    .accordion-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease;

        .content {
            padding: 0 0 1.33rem;

            p {
                font-size: clamp(0.9rem,2vw,1rem);
            }
        }
    }

    .files {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
    }

    .file {
        border-bottom: $border-brown-light;
        padding: 1rem 0 0;

        &:last-child {
            border-bottom: none;
        }

        a {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            font-size: $regular;
            padding: 0 0 1.33rem;
            font-weight: 400;
            color: $color-text;

            .file-info {
                opacity: 0.6;
                font-size: $xsmall;
            }
        }

        &:hover {
            a {
                color: $color-brown-dark;

                .file-info {
                    opacity: 0.9;
                    font-size: $xsmall;
                }
                
            }
        }

        span {
            font-size: $xsmall;
            text-transform: uppercase;
        }
    }
}