//
// Footer Styles
// --------------------------------------------------
@import "../../../assets/styles/scss/variables";

#footer {
	background-color: $color-brown-dark;
	color: #ffffff;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	padding: 4rem 0 1rem;

	a {
		color: $color-white;

		&:hover {
			color: $color-brown-secondary;
		}
	}

	.hebeisen {
		font-weight: 700;
		font-size: 16px;
		padding: 0 0 1rem;
	}

	.logo {
		padding: .5rem 0 2.5rem;
	}

	#footer-top {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		padding: 0 0 4rem;

		.footer-grid {
			display: flex;
			flex-direction: column;
			line-height: 24px;

		}

		.other-socials {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}

		.footer-social-links {
			display: flex;

			.other-socials {
				color: $color-white;

				&:hover {
					color: $color-brown-secondary;
				}
			}

		}

		.right {
			.socials {
				padding: 2px 0 0;
			}

			.language-switcher {
				padding: 2px 0 0;

				.wpml-floating-language-switcher {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 10px;
					font-weight: 300;

					.active {
						font-weight: 600;
					}
				}
			}

			display: flex;
			align-items: center;
			gap: 20px;
		}

	}

	.footer-bloc-title {
		margin-top: 0;
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			a {
				color: #ffffff;

				&:hover {
					color: $color-primary;
				}
			}
		}
	}

	.footer-social-links {
		a {
			display: inline-block;
			margin-right: 15px;

			i {
				font-size: 20px;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	#footer-bottom {
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #A5A3A2;

		a {
			color: #A5A3A2;
			font-size: 14px;
		}

		.footer-copyright {
			font-size: 14px;
			display: flex;
			gap: 2.28rem
		}
	}

	@media (max-width:1199px) {

		#footer-bottom {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			text-align: left;
			align-items: flex-start;
		}
	}

	@media (max-width:767px) {

		font-size: 12px;

		#footer-top {
			flex-wrap: wrap;
			row-gap: 20px;
		}

		.troisdeuxun-link {
			padding-top: .2rem;
		}

		#footer-bottom {
			font-size: 12px;
			padding-bottom: 3rem;

			.footer-copyright {
				flex-wrap: wrap;
				row-gap: 5px;
				column-gap: 0;
				justify-content: flex-start;
				text-align: left;
				align-items: flex-start;

				.policy {
					align-items: center;
					display: flex;

					&:after {
						content: "";
						margin: 0 15px;
						width: 1px;
						background-color: #A5A3A2;
						height: 10px;
					}
				}
			}
		}
	}

	@media (max-width:380px) {
		#footer-top {
			.footer-grid {
				display: grid;
				grid-template-columns: repeat(1, 1fr);
				line-height: 24px;

				div:nth-child(odd) {
					padding-right: 0;
				}

				div:nth-child(even) {
					margin-left: 0;

					&:before {
						content: "";
						margin-right: 0;
						width: 0;
						height: 0x;
					}
				}
			}
		}
	}
}

.powered {
	position: relative;
	color: inherit;

	&:hover {
		color: inherit;
		text-decoration: none;

		#logo-alt-footer-trois {
			fill: #FFD94A;
			transform: scale(1.1);
		}

		#logo-alt-footer-deux {
			fill: #FFD94A;
			transform: scale(1.1);
		}

		#logo-alt-footer-un {
			fill: #FFD94A;
			transform: scale(1.1);
		}

		&:after {
			width: 100px;
		}
	}

	svg {
		overflow: visible;
		width: 50px;
		height: 24px;
		margin: 0 0 0 5px;
		position: relative;
		top: -2px;
		vertical-align: middle;
		width: 34px;

		#logo-alt-footer-trois {
			fill: #A5A3A2;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
			transform: scale(1);
			transform-origin: center;
		}

		#logo-alt-footer-deux {
			fill: #A5A3A2;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
			transform: scale(1);
			transform-origin: center;
		}

		#logo-alt-footer-un {
			fill: #A5A3A2;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
			transform: scale(1);
			transform-origin: center;
		}
	}

	&:after {
		display: block;
		content: '';
		position: absolute;
		bottom: -2px;
		left: 83px;
		width: 0px;
		height: 2px;
		background-color: #FFD94A;
		transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
}