@import "../../assets/styles/scss/variables";

.member-card {
    color: $color-text;
    display: flex;
    flex-direction: column;
    gap: .40rem;

    .member-card-img {
        width: 100%;
        height: 19rem;
        min-height: 19rem;
        max-height: 19rem;
        max-width: 100%;
        object-fit: cover;
        object-position: center;
    }

    .member-card-info {
        width: 100%;
        display: flex;
        flex-direction: column;

        h3 {
            font-size: $regular;
            line-height: $massive;
            margin-bottom: -3px;
        }
        p {
            margin-bottom: -3px;
        }
        a {
            color: $color-text;
            font-size: $regular;
        }
    }

    @media (max-width:1199px) {
        .member-card-img {
            min-height: 16rem;
            max-height: 16rem;
        }
    }

    @media (max-width:991px) {
        .member-card-img {
            min-height: 14rem;
            max-height: 14rem;
        }
    }
}
