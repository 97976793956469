//
// Base Styles
// --------------------------------------------------
@import "../../../assets/styles/scss/variables";

html,
body {
  font-family: $font-base;
  font-size: 16px;
  line-height: $text-base-line-height;
  font-weight: normal;
  color: $color-text;
  scroll-behavior: smooth;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.is-hidden,
.hidden {
  display: none !important;
}

.xsmall {
  font-size: $xsmall;
  line-height: normal;
  font-weight: normal;
}

.small {
  font-size: $small;
  line-height: normal;
  font-weight: normal;
}

p,
li,
.regular {
  font-size: $regular;
  line-height: 1.4rem;
  font-weight: normal;
}

p {
  margin-bottom: .8rem;
  font-weight: 400;

  &:last-child {
    margin-bottom: 0;
  }
}

h6,
.medium {
  font-size: $medium;
  line-height: normal;
  font-weight: 500;
}

h5,
.big {
  font-size: $big;
  line-height: normal;
  font-weight: 500;
}

h4,
.large {
  font-size: clamp($medium, 1.25vw, $bigmedium);
  line-height: clamp($big, $large, $large);
  font-weight: 500;
}

h3,
.massive {
  font-size: clamp($bigmedium, 1.375vw, $big);
  line-height: clamp($big, $large, $large);
  font-weight: 700;
}

h2,
.huge {
  font-size: clamp($medium, 3.7vw, $huge );
  line-height: normal;
  font-weight: 700;
}

h1,
.gigantic {
  font-size: clamp($medium, 3.7vw, $huge);
  line-height: normal;
  font-weight: 700;
}

.no-gradient {
  background-color: #32302D;
}

.gradient {
  background: linear-gradient(to bottom, #3D3A37, #32302D, #272523);
  background: -webkit-linear-gradient(top, #3D3A37, #32302D, #272523);
  background: -moz-linear-gradient(top, #3D3A37, #32302D, #272523);
  background: -o-linear-gradient(top, #3D3A37, #32302D, #272523);
  background: -ms-linear-gradient(top, #3D3A37, #32302D, #272523);
}

.masonery {
  display: block;
  column-count: 3;
  gap: 0.78rem;

  div {
      margin-bottom: 0.78rem;
      break-inside: avoid;
      position: relative;
      overflow: hidden;

      a {
        display: block;
        height: 100%;
        line-height: 0;
      }
  }

  @media (max-width:599px) {
      column-count: 2;
  }
}