//
// Template Styles
// --------------------------------------------------
@import "../../../assets/styles/scss/variables";

#main {
    padding-top: 5.1rem; // Header height
    z-index: 15;

    @media (max-width:991px) {
        padding-top: 4.1rem;
    }
}

.page-content {
    padding: 2rem 0 8.75rem;
}

#page-homepage {

    #hv-current-project {
        padding: 8.11rem 0 6.89rem;

        h2 {
            padding: 0 0 2.06rem;
        }

        .swiper-pagination {
            display: none;
        }

        .btn-primary {
            margin: 2.45rem 0 0;
        }

        @media (max-width:991px) {
            .swiper-pagination {
                position: relative;
                top: 6px;
                display: flex;

                .swiper-pagination-bullet-active {
                    background-color: $color-brown-dark;
                }
            }

            .swiper-slide {
                max-width: 70vw;
                width: 70vw;
            }
        }
    }

    #hv-skills {
        height: 42.333rem;
        background-color: $color-brown-dark;
        color: $color-white;

        .hv-skill-background-img {
            height: 100%;
            z-index: 0;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .skill-background {
            height: 100%;
            display: grid;
            grid-template-columns: 45% 55%;
        }

        .container {
            top: -100%;
            height: 100%;
        }

        .skills-grid {
            height: 100%;
            display: grid;
            position: relative;
            top: 0;
            grid-template-columns: 35% 65%;

            &>div {
                height: 100%;
                padding: 0 0 3.33rem;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                h2 {
                    padding: 0 0 0.94rem;
                }

                .btn-primary {
                    margin: 2.44rem 0 0;
                }
            }
        }

        @media (max-width:991px) {
            display: flex;
            flex-direction: column;

            .skill-background {
                display: contents;
            }

            .container {
                top: 0;

                .skills-grid {
                    display: flex;
                    width: 100%;
                }
            }
        }
    }

    #hv-instagram {
        padding: 8.11rem 0 6.89rem;

        h2 {
            padding: 0 0 2.06rem;
        }

        #sbi_load {
            margin-top: 2rem;
            display: flex;
            justify-content: center;
            gap: 2rem;
        }

        .sbi_load_btn {
            width: fit-content;
            display: flex;
            gap: 0.44rem;
            align-items: center;
            margin: 0;
            background: $color-white !important;
            border: solid 1px $color-brown !important;
            color: $color-brown !important;
            box-shadow: inset 0 0 0px 0px transparent !important;
            border-radius: 0 !important;
            font-weight: 500;
            font-size: 15px !important;
            padding: .32rem 1rem .32rem;
            position: relative;
            z-index: 100;

            &:hover {
                box-shadow: none !important;
                background: $color-white !important;
                border: solid 1px $color-brown !important;
                color: $color-white !important;
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 100%;
                background-color: $color-brown !important;
                opacity: 1;
                transition: width 0.3s ease-out;
                z-index: -1;
            }

            &:hover::before {
                width: 100%;
            }
        }

        .sbi_follow_btn.sbi_custom {
            a {
                width: fit-content;
                display: flex;
                gap: 0.44rem;
                align-items: center;
                background-color: $color-transparent !important;
                border: solid 2px $color-brown;
                border-radius: 0 !important;
                color: $color-brown;
                font-weight: 700;
                margin: 0;
                padding: .52rem 1rem .52rem;
                position: relative;

                svg {
                    font-size: 18px;
                }

                &:hover {
                    color: $color-white !important;
                    background: $color-brown-dark !important;
                    border: solid 2px $color-brown-dark !important;
                    box-shadow: inset 0 0 10px 20px $color-brown-dark !important;
                }
            }
        }
    }
}

#page-about {
    #about-us {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8.1rem;
        padding: 0 0 6.67rem;

        .about-intro {
            p {
                font-size: 1.11111rem;
                font-weight: 700;
            }
        }

        @media (max-width:991px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 4rem;
        }
    }

    #member-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 1.22rem;
        row-gap: 4.44rem;

        @media (max-width:991px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width:504px) {
            display: flex;
            flex-direction: column;
        }
    }

    #book {
        background: linear-gradient(90deg, $color-brown 47%, $color-beige 47%);

        .container {
            display: grid;
            grid-template-columns: 47% 53%;
            transition: all 0.3s ease;

            .book-preview {
                display: flex;
                align-items: center;
                padding: 6.2rem 10.44rem 6.2rem 6.2rem;
                transition: all 0.3s ease;

                .book-img {
                    box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, .25);
                }
            }

            .book-info {
                padding: 9.06rem 15% 14.22rem 5.56rem;
                transition: all 0.3s ease;

                h3 {
                    padding: 0.61rem 0 1.11rem;
                }

                .book-infos {
                    padding: 3.33rem 0 2.22rem;

                    .infos {
                        list-style-type: none;
                        margin: 0;
                        padding: 0;

                        li {
                            display: grid;
                            grid-template-columns: 100px auto;
                            border-bottom: solid 1px rgba(154, 149, 143, 0.20);
                            padding: 0.44rem 0;
                        }

                        & li:last-child {
                            border: 0;
                        }
                    }
                }
            }
        }

        @media (max-width:1240px) {

            .container {

                .book-preview {
                    padding: 5rem;
                    transition: all 0.3s ease;
                }

                .book-info {
                    padding: 5rem;
                    transition: all 0.3s ease;
                }
            }
        }

        @media (max-width:991px) {
            background: linear-gradient(90deg, $color-brown 41%, $color-beige 41%);

            .container {
                grid-template-columns: 40% 60%;
                transition: all 0.3s ease;

                .book-preview {
                    padding: 3rem;
                    transition: all 0.3s ease;
                }

                .book-info {
                    padding: 3rem;
                    transition: all 0.3s ease;

                    .book-infos {
                        padding: 1rem 0 1.22rem;
                    }
                }
            }
        }

        @media (max-width:767px) {
            background: $color-transparent;

            .container {
                max-width: 100vw;
                display: flex;
                flex-direction: column;
                transition: all 0.3s ease;

                .book-preview {
                    background: $color-brown;
                    padding: 2rem;
                    transition: all 0.3s ease;

                    .book-img {
                        height: 100%;
                    }
                }

                .book-info {
                    background: $color-beige;
                    padding: 4rem 2rem;
                    transition: all 0.3s ease;
                }
            }
        }
    }
}

#page-project {
    #filters-select-container {
        display: none;
    }

    #filters {
        width: 70%;
        transition: all 0.3s ease;
    }

    #loader {
        min-height: 400px;
        padding: 1rem 0;

        img {
            width: 100px;
        }
    }

    #project-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.44rem;
        row-gap: 3.75rem;

        @media (max-width:599px) {
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }
    }

    @media (max-width:1440px) {
        #filters {
            width: 80%;
        }
    }

    @media (max-width:1290px) {
        #filters {
            width: 90%;
        }
    }

    @media (max-width:991px) {
        #filters {
            width: 100%;
        }
    }

    @media(max-width: 767px) {
        #filters-select-container {
            margin-top: -4.2rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 100%;

            #filters-select:after {
                transform: rotate(0deg);
            }

            #filters-select.is-open:after {
                transform: rotate(180deg);
            }
        }

        #filters {
            overflow: hidden;
            padding-top: 2.2rem;
            transition: all 0.3s ease;
            max-height: 0;

            ul {
                flex-direction: column;
            }
        }
    }

    @media(max-width: 599px) {
        #filters-select-container {
            margin-top: -3.3rem;
        }
    }
}

#page-accordion {
    #page-content {
        padding: 0 0 7rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 8.11rem;

        .page-intro-hat {
            font-size: $bigmedium;
            font-weight: 700;
            line-height: 1.6rem;
            padding: 0 0 1.33rem;
        }

        @media (max-width:1199px) {
            display: flex;
            flex-direction: column;
            gap: 4rem;

            .page-intro {
                padding: 0 0 1.5rem;
            }
        }

        @media (max-width:767px) {

            .page-intro {
                .page-intro-hat {
                    font-size: 1.1rem;
                    font-weight: 700;
                    line-height: 1.4rem;
                }

                p {
                    font-size: 1rem;
                    line-height: 1.4rem;
                }
            }
        }
    }

    .m3-member {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 1.22rem;
        row-gap: 4.44rem;
        padding: 0 0 6.67rem;

        @media (max-width:991px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width:504px) {
            display: flex;
            flex-direction: column;
        }
    }

    .accordion-title {
        cursor: pointer;
    }

    .footer-img {
        height: 55rem;

        img {
            height: 55rem;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }

        @media (max-width:991px) {
            height: 40svh;

            img {
                height: 40svh;
            }
        }
    }
}

#page-projet-single {

    #project-title {
        display: flex;
        flex-direction: column;
        gap: 0.22rem;
        padding: 2.5rem 0 0;

        h1 {
            font-size: 1.25rem;
        }
    }

    ul {
        padding: 0 0 0 20px;
    }

    .quote {
        p {
            font-size: 1.15rem;
        }
    }

    #project-details {
        padding: 1.67rem 0 8.03rem;
        display: grid;
        grid-template-columns: 66.3% 33.7%;
        gap: .78rem;

        .left {
            display: flex;
            flex-direction: column;
            padding: 0 10rem 0 0;

            .project-intro {
                padding: 0.6rem 0 4.44rem;

                p {
                    font-size: 16px;
                }
            }
        }

        .right {
            padding: 0;

            .infos {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    display: grid;
                    grid-template-columns: (40% 60%);
                    border-bottom: solid 1px rgba(154, 149, 143, 0.20);
                    padding: .61rem 1rem .61rem 0;
                    font-size: 16px;
                    font-weight: 700;
                }

                .list-info {
                    font-size: 16px;
                    font-weight: 300;
                }

                & li:last-child {
                    border: 0;
                }
            }
        }

        @media (max-width:1199px) {
            .left {
                padding: 0 5rem 0 0;
            }

            .right {
                padding: 0;
            }
        }

        @media (max-width:991px) {
            display: flex;
            flex-direction: column;
            gap: 5rem;

            .left {
                padding: 0;

                .project-intro {
                    padding: 0 0 1.7rem;

                    p {
                        text-align: left;
                    }
                }
            }

            .right {

                .infos {

                    li {
                        span:not(.list-info) {
                            text-align: left;
                            padding-right: 10px;
                        }
                    }

                    & li:last-child {
                        border: 0;
                    }
                }
            }
        }

        @media (max-width:767px) {

            .right {
                .infos {
                    li {
                        display: flex;
                        flex-direction: column;
                        padding: .5rem 0;
                    }
                }
            }
        }

        @media (max-width:599px) {
            padding: 1.67rem 0 4.03rem;
            gap: 3rem;
        }
    }

    #project-gallery {
        padding: 0 0 9.37rem;
    }

    .projet-copyright {
        font-size: 14px;
    }

    #related-posts {
        padding: 5.65rem 0;
        background: $color-beige;

        .title {
            padding: 0 0 2.06rem;
        }
    }

    .swiper-pagination {
        display: none;
    }

    @media (max-width:991px) {
        .swiper-pagination {
            position: relative;
            top: 6px;
            display: block;

            .swiper-pagination-bullet-active {
                background-color: $color-brown-dark;
            }
        }

        .swiper-slide {
            max-width: 70vw;
            width: 70vw;
        }
    }

}

#page-contact {
    .page-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        color: $color-brown;

        a {
            color: $color-brown;
        }

        .left {
            display: flex;
            flex-direction: column;
            gap: .36rem;
            line-height: 1.3556rem;
            font-size: $regular;

            .contact-title {
                font-weight: 700;
            }

            .mail-phone,
            .contact-stree-info,
            .contact-street {
                display: flex;
                flex-direction: column;
                gap: 0.3rem;
            }
        }

        @media (max-width:767px) {
            display: flex;
            flex-direction: column;
            gap: 4rem;

            .left {
                gap: 1.56rem;
            }
        }
    }

    .map-img {
        height: 65vh;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }

        @media (max-width:991px) {
            height: 50vh;
        }
    }

    #field_2_8 {
        label {
            font-size: 14px;
        }
    }
}

#error-template {
    .error-header {
        display: flex;
        flex-direction: column;
        padding: 4rem 0;
        height: auto;
        position: relative;
        color: $color-white;

        .top {
            width: 50%;
            position: relative;
            z-index: 1;
            padding: 0 0 2rem;

            .error-title {
                display: flex;
                flex-direction: column;
                font-size: 10vw;
                color: $color-white;
            }

            .error-details {
                color: $color-white;
            }
        }

        .bottom {
            width: 50%;
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .header-error-bg {
            background-color: $color-brown-dark;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            .header-error-img {
                height: 100%;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }
    }

    .title {
        padding: 0 0 20px;
    }

    @media(max-width: 991px) {
        .error-header {
            .top {
                width: 100%;

                .error-title {
                    font-size: 6rem;
                    color: $color-brown;
                }

                .error-details {
                    color: $color-brown;
                }
            }

            .bottom {
                width: 100%;
            }

            .header-error-bg {
                display: none;
            }
        }
    }
}