@import "../../assets/styles/scss/variables";

.logo {
    svg {
        width: 450px;

        @media (max-width: 1199px) {
            width: 350px;
        }
    }

    @media (max-width: 540px) {
        width: 100%;

        svg {
            width: 100%;
        }
    }
}