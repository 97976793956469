@import "../../assets/styles/scss/variables";

.header-standard {
    padding: 4rem 0 2rem;

    @media (max-width:991px) {
        padding: 4.27rem 0 2.22rem;
    }

    @media (max-width:599px) {
        padding: 3.27rem 0 1.57rem;
    }
}