@import "../../assets/styles/scss/variables";

.project-card {
    color: $color-text;
    display: flex;
    flex-direction: column;
    gap: .40rem;

    h3 {
        font-size: $regular;
        line-height: $large;
    }

    .project-image-container {
        overflow: hidden;
        position: relative;
        min-height: 19rem;
        max-height: 19rem;
        height: 19rem;
        transition: all 0.6s ease;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }


    .project-image-backdrop {
        left: -100%;
        position: relative;
    }

    .project-card-info {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    @media (max-width:1199px) {
        .project-image-container {
            min-height: 16rem;
            max-height: 16rem;
            height: 16rem;
        }
    }

    @media (max-width:991px) {
        .project-image-container {
            min-height: 14rem;
            max-height: 14rem;
            height: 14rem;
        }
    }

    &:hover {
        .project-image-backdrop {
            height: 100%;
            width: 100%;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            background: $color-brown;
            transition: all 0.2s ease;
            opacity: 0.5;
        }
    }
}